(function (angular) {
    'use strict';

    angular.module("app")
        .factory('_i18n', function (gettext, gettextCatalog) {
            var strings = {
                nodulationQuality: {
                    good: initTextConstant(gettext("Good", null, "nodulationQuality"), "nodulationQuality"),
                    medium: initTextConstant(gettext("Medium", null, "nodulationQuality"), "nodulationQuality"),
                    bad: initTextConstant(gettext("Bad", null, "nodulationQuality"), "nodulationQuality")
                },
                auth: {
                    notifications: {
                        restorePasswordCheck: {
                            error: initTextConstant(gettext("password restore link invalid", null, "notification - error"), "notification - error")
                        },
                        confirmEmail: {
                            success: initTextConstant(gettext("email confirmed", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("email confirm link invalid", null, "notification - error"), "notification - error")
                        },
                        approveInvitation: {
                            success: initTextConstant(gettext("invitation accepted", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("invitation link invalid", null, "notification - error"), "notification - error")
                        },
                        login: {
                            error: {
                                badCredentials: initTextConstant(gettext("Sorry, your email address or password<br>is not correct. Please try again.", null, "notification - error"), "notification - error"),
                                userDisabled: initTextConstant(gettext("In order to use Fertyle, the email address of your account needs to be verified.", null, "notification - error"), "notification - error")
                            }
                        }
                    }
                },
                calendar: {
                    today: initTextConstant(gettext("Today")),
                    granularities: {
                        s: initTextConstant(gettext('Stages')),
                        m: initTextConstant(gettext("Months")),
                        w: initTextConstant(gettext("Weeks")),
                        d: initTextConstant(gettext("Days")),
                        h: initTextConstant(gettext("Hours"))
                    }
                },
                weather: {
                    rows: {
                        w: initTextConstant(gettext("Weather")),
                        sr: initTextConstant(gettext("Solar Radiation")),
                        ws: initTextConstant(gettext("Wind Speed & Direction")),
                        at: initTextConstant(gettext("Air Temperature")),
                        h: initTextConstant(gettext("Humidity")),
                        e: initTextConstant(gettext("Evapotranspiration")),
                        ct: initTextConstant(gettext("Canopy Temperature")),
                        dpt: initTextConstant(gettext("Dew Point Temperature")),
                        lw: initTextConstant(gettext("Leaf Wetness")),
                        st: initTextConstant(gettext("Soil Tempearature")),
                        rf: initTextConstant(gettext("Rain Falls"))
                    }
                },
                task: {
                    notifications: {
                        saveAssignment: {
                            success: initTextConstant(gettext("Task saved", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Task not saved", null, "notification - error"), "notification - error"),
                            errorNoSowing: initTextConstant(gettext("Task not saved, crop not found for task dates", null, "notification - error"), "notification - error")
                        }
                    },
                    status: {
                        NEW: initTextConstant(gettext("New", null, "task status"), "task status"),
                        ACCEPTED: initTextConstant(gettext("Accepted", null, "task status"),"task status"),
                        STARTED: initTextConstant(gettext("Started", null, "task status"),"task status"),
                        UNASSIGNED: initTextConstant(gettext("Unassigned", null, "task status"), "task status"),
                        ASSIGNED: initTextConstant(gettext("Assigned", null, "task status"), "task status"),
                        IN_PROGRESS: initTextConstant(gettext("In Progress", null, "task status"), "task status"),
                        PAUSED: initTextConstant(gettext("Paused", null, "task status"), "task status"),
                        DONE: initTextConstant(gettext("Done", null, "task status"), "task status"),
                        ABANDONED: initTextConstant(gettext("Abandoned", null, "task status"), "task status")
                    },
                    activity: {
                        STAND: initTextConstant(gettext("Stand", null, "activity"), "activity"),
                        DISEASES: initTextConstant(gettext("Diseases", null, "activity"), "activity"),
                        INSECTS: initTextConstant(gettext("Insects", null, "activity"), "activity"),
                        FENOLOGY: initTextConstant(gettext("Fenology", null, "activity"), "activity"),
                        WEEDS: initTextConstant(gettext("Weeds", null, "activity"), "activity"),
                        NOTE: initTextConstant(gettext("Note", null, "activity"), "activity"),
                        PA_DISEASE: initTextConstant(gettext("P/A Diseases", null, "activity"), "activity"),
                        PA_INSECT: initTextConstant(gettext("P/A Insects", null, "activity"), "activity"),
                        //REGULAR_NOTE: initTextConstant(gettext("Note", null, "activity"), "activity")
                    },
                    activityOptions: {
                        count: initTextConstant(gettext("Count", null, "activity options"), "activity options"),
                        insect: initTextConstant(gettext("Insect", null, "activity options"), "activity options"),
                        stage: initTextConstant(gettext("Stage", null, "activity options"), "activity options"),
                        stand: initTextConstant(gettext('Crop stage', null, "activity options"), "activity options"),
                        weed: initTextConstant(gettext("Weed", null, "activity options"), "activity options"),
	                      weedType: initTextConstant(gettext("Weed", null, "activity options"), "activity options"),
	                      weedStage: initTextConstant(gettext("Stage", null, "activity options"), "activity options"),
	                      severity: initTextConstant(gettext("Severity", null, "activity options"), "activity options"),
                        disease: initTextConstant(gettext("Disease", null, "activity options"), "activity options"),
                        diseaseType: initTextConstant(gettext("Disease", null, "activity options"), "activity options"),
                        diseaseSeverity: initTextConstant(gettext("Severity", null, "activity options"), "activity options"),
                        leaves: initTextConstant(gettext("Leaves count", null, "activity options"), "activity options"),
                        leafCount: initTextConstant(gettext("Leaves count", null, "activity options"), "activity options"),
                        leafsCount: initTextConstant(gettext("Leaves count", null, "activity options"), "activity options"),
                        leavesCount: initTextConstant(gettext("Leaves count", null, "activity options"), "activity options"),
                        rootLength: initTextConstant(gettext("Root length", null, "activity options"), "activity options"),
                        kernelsCount: initTextConstant(gettext("Kernels count", null, "activity options"), "activity options"),
                        cropStage: initTextConstant(gettext("Crop stage", null, "activity options"), "activity options"),
                        flowerCount: initTextConstant(gettext("Flowers count", null, "activity options"), "activity options"),
                        flowersCount: initTextConstant(gettext("Flowers count", null, "activity options"), "activity options"),
                        beanCount: initTextConstant(gettext("Beans count", null, "activity options"), "activity options"),
                        beansCount: initTextConstant(gettext("Beans count", null, "activity options"), "activity options"),
                        fenology: initTextConstant(gettext("Crop stage", null, "activity options"), "activity option"),
                        silksLength: initTextConstant(gettext("Silks count", null, "activity options"), "activity options"),
                        silkCount: initTextConstant(gettext("Silks count", null, "activity options"), "activity options"),
                        silksCount: initTextConstant(gettext("Silks count", null, "activity options"), "activity options"),
                        plantHeight: initTextConstant(gettext("Plant's height", null, "activity options"), "activity options"),
                        nodesCount: initTextConstant(gettext("Nodes count", null, "activity options"), "activity options"),
                        nodulationQuality: initTextConstant(gettext("Nodulation goodness", null, "activity options"), "activity options"),
                        root: initTextConstant(gettext("Root length", null, "activity options"), "activity options"),
                        crop: initTextConstant(gettext("Crop stage", null, "activity options"), "activity options"),
                        bean: initTextConstant(gettext("Beans count", null, "activity options"), "activity options"),
                        silk: initTextConstant(gettext("Silks count", null, "activity options"), "activity options"),
	                      silks: initTextConstant(gettext("Silks count", null, "activity options"), "activity options"),
                        flower: initTextConstant(gettext("Flowers count", null, "activity options"), "activity options"),
	                      kernels: initTextConstant(gettext("Kernels count", null, "activity options"), "activity options"),
	                      tassel: initTextConstant(gettext("Tassel", null, "activity options"), "activity options"),
                        smallBollsCount: initTextConstant(gettext("Small bolls count", null, "activity options"), "activity options"),
                        mediumBollsCount: initTextConstant(gettext("Medium bolls Count", null, "activity options"), "activity options"),
                        bigBollsCount: initTextConstant(gettext("Big bolls count", null, "activity options"), "activity options"),
                        squaresCount: initTextConstant(gettext("Squares count", null, "activity options"), "activity options"),
                        plantStage: initTextConstant(gettext("Plant stage", null, "activity options"), "activity options"),
                        principalStage: initTextConstant(gettext("Principal stage", null, "activity options"), "activity options"),
                        tillersCount: initTextConstant(gettext("Tillers count", null, "activity options"), "activity options"),
                        stemsCount: initTextConstant(gettext("Stems count", null, "activity options"), "activity options"),
                        earsCount: initTextConstant(gettext("Ears count", null, "activity options"), "activity options"),
                        grainsPerEar: initTextConstant(gettext("Grains per ear", null, "activity options"), "activity options"),
                        note: initTextConstant(gettext("Note", null, "activity options"), "activity options"),
                        selectedPlantsCount: initTextConstant(gettext("Plants count", null, "activity options"), "activity options"),
                        selectedEvaluationScaleItem: initTextConstant(gettext("Scale item", null, "activity options"), "activity options"),
                        isPresent: initTextConstant(gettext("Present", null, "activity options"), "activity options"),
                        regular_note: initTextConstant(gettext("Note", null, "activity options"), "activity options"),
                    }
                },
                settings: {
                    roles: {
                        ROLE_ACCOUNT_OWNER: initTextConstant(gettext("Account Owner")),
                        ROLE_ACCOUNT_EDIT: initTextConstant(gettext("Edit User")),
                        ROLE_ACCOUNT_VIEW: initTextConstant(gettext("View Only"))
                    },
                    notifications: {
                        saveUser: {
                            success: initTextConstant(gettext("New user {{userName}} was invited", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("New user was not added. Probably the email address you entered is already in use", null, "notification - error"), "notification - error"),
                        },
                        updateUser: {
                            success: initTextConstant(gettext("User Settings were updated successfully", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("User Settings were not updated", null, "notification - error"), "notification - error")
                        },
                        addAccount: {
                            success: initTextConstant(gettext("New account {{accountName}} was added", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("New account was not added", null, "notification - error"), "notification - error")
                        },
                        updateAccount: {
                            success: initTextConstant(gettext("Account Settings were updated successfully", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Account Settings were not updated", null, "notification - error"), "notification - error")
                        },
                        updateAccountFirstDayOfWeek: {
                            success: initTextConstant(gettext("First Day Of Week was updated", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("First Day Of Week was not updated", null, "notification - error"), "notification - error")
                        },
                        deleteInvitation: {
                            success: initTextConstant(gettext("Invitation was canceled", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Invitation was not canceled", null, "notification - error"), "notification - error")
                        },
                        resendInvitation: {
                            success: initTextConstant(gettext("User was re-invited", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("User was not re-invited", null, "notification - error"), "notification - error")
                        },
                        updateFields: {
                            success: initTextConstant(gettext("Changes were successfully saved", null, "notification - success"), "notification - success")
                        }
                    }
                },
                crops: {
                    notifications: {
                        createCrop: {
                            success: initTextConstant(gettext("Crop was created", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Crop was not created", null, "notification - error"), "notification - error")
                        },
                        updateCrop: {
                            success: initTextConstant(gettext("Crop was updated", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Crop was not updated", null, "notification - error"), "notification - error")
                        },
                        deleteCrop: {
                            success: initTextConstant(gettext("Crop was deleted", null, "notification - success"), "notification - success"),
                            error: initTextConstant(gettext("Crop was not deleted", null, "notification - error"), "notification - error")
                        }
                    }
                },
                common: {
                    notifications: {
                        apiError: initTextConstant(gettext("An error occurred", null, "notification - error"), "notification - error")
                    }
                }
            };

            function initTextConstant(text, context) {
                return {text: text, context: context};
            }

            /**
             * Usage: _._i18n.getString("stringID", {"variable": "value"});
             *        _._i18n.getString("settings.notifications.success.addAccount", {"accountName": "Nikolai"});
             *
             * @param {String} str - string ID
             * @param {Object|null} [data] - variable to pass
             * @returns {String} - translated string
             */
            function getString(str, data) {
                var bean = str.split('.').reduce((obj, i) => obj[i], strings);
                if (bean) {
                    return gettextCatalog.getString(bean.text, data, bean.context);
                } else {
                    console.log("MISSING: " + str);
                    return gettextCatalog.getString(str, data, null);
                }
            }

            function getTranslation(bundle, lang) {
                var theString = "";
                var arr = bundle.split(",");

                arr.some(str => {
                    if (str.startsWith(lang + ":")) {
                        theString = str.substring(lang.length + 1);
                        return true;
                    }
                });

                return theString;
            }

            function getEnStringFromBundle(bundle) {
                let enLang = getTranslation(bundle, "en");

                return (enLang != undefined) ? enLang: bundle;
            }

            return {
                getString: function (str, data) {
                    return getString(str, data);
                },
                getStringFromBundleLat: function(bundle) { //string in "current_language - lat_language" or "lat_language" format
                    let lang = gettextCatalog.getCurrentLanguage();

                    if (lang === "pt_BR"){
                        lang = "pt";
                    }

                    if (bundle) {
                        let result = "",
                            currentLang = getTranslation(bundle, lang),
                            lat = getTranslation(bundle, "lat");

                        if (currentLang) {
                            result = currentLang;
                        }
                        if (lat) {
                            if (result) {
                                result = result + " - " + lat;
                            } else {
                                result = lat;
                            }
                        }

                        return result;
                    }
                    return "";
                },
                getStringFromBundleEn: function(bundle) { //string in "current_language" or "en" format
                    let lang = gettextCatalog.getCurrentLanguage();

                    if (lang === "pt_BR"){
                        lang = "pt";
                    }

                    if (bundle) {
                        let currentLang = getTranslation(bundle, lang);

                        if (currentLang) {
                            return currentLang;
                        } else {
                            return getEnStringFromBundle(bundle);
                        }
                    }
                    return "";
                },
                getEnStringFromBundle: getEnStringFromBundle
            };
        })
        .run(function ($rootScope, gettextCatalog, _appStorage, tmhDynamicLocale) {
            var lang = _appStorage.get('lang') || "ru";

            if (['en', 'pt_BR', 'ru'].indexOf(lang) == -1) {
                lang = "ru";
            }

            gettextCatalog.setCurrentLanguage(lang);

            if (lang == 'pt_BR') {
                moment.locale('pt-br');
                tmhDynamicLocale.set('pt-br');
            }
            if (lang == 'ru') {
                moment.locale('ru');
                tmhDynamicLocale.set('ru-ru');
            }
            if (lang == 'en') {
                moment.locale('en');
                tmhDynamicLocale.set('en');
            }
        });
}(angular));